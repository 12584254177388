
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./support.scss"
export default function Support({ appLong }) {

  const author = "Timm Kent"
  const address = "Krefelder Wall 9, 50670 Köln, Germany"
  const app = appLong
  const email = "info@madetk.com"
  const [lang, setLand] = useState("en")

  return (
    <div className='privacy'>
      <h1>Contact Support {appLong} </h1>

      <h2>Get directly into contact with us</h2>

      <p><a href="mailto:info@madetk.com">info@madetk.com</a></p>

    


    </div>
  )
}
