import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./privacy.scss"
export default function Privacy({ appLong }) {

  const author = "Timm Kent"
  const address = "Zeltinger Strasse 7, 50670 Koeln, Germany"
  const app = appLong
  const email = "info@madetk.com"
  const [lang, setLand] = useState("en")

  return (
    <div className='privacy'>
      <h1>Privacy Policy {appLong} </h1>

      <p>{author}, {address}, is controller of personal data and operator of the {appLong} application (or “Application”)<br></br> <br></br> When you use the {app} application and websites (“Services”) you entrust your data to us, including your personal data.</p>
      <p>This privacy policy will inform you of the data we collect, why we collect the data, how long we store the data, and what we do with the data.</p>
      <p>If you want to use the {appLong} application, please thoroughly acquaint yourself with the content of this privacy policy. By signing up you agree with the privacy policy and you acknowledge the processing of personal data by {author} as the controller of the personal data.</p>


      <h2>What data do we collect and process?</h2>

      <p>The {app} App collects and processes personal data (such as your name or date of birth) and other types of data. We need this data for providing our Services, we cannot ovide the correct connections and our Application cannot work right without the data about you and some information about your preferences. We also collect information about your use of our Services, such as location, access log, activity and information from Facebook or Instagram, that are necessary for the proper functioning of the Application and performance of our contract.</p>

      <h2>Information you provide</h2>

      <p>When registering on the Application, you may use the registration form or register through Email, Facebook, Google, Apple or Instagram.</p>

      <p>When registering with the registration form, you provide the following information (if your profile is complete): your name, e-mail address, date of birth, sexual orientation, gender, relationship status, preferences (alcohol consumption, smoking, interests), appearance (eyes colour, hair colour, body size, tattoo, piercing) and photos.</p>

      <p>When registering through your Facebook account, you provide information from your public profile on Facebook (in accordance with your privacy settings on Facebook), such as your name, profile photo, sex, age, and your Facebook ID (user number). In addition, you can provide the following information to the Application: your e-mail address, date of birth, photograph, and your interests (“likes” on Facebook).</p>

      <p>When you subscribe to a paid service, you provide to our payment service provider (Apple, Google) payment information (card number and other payment information) in accordance with the Privacy Policy of these providers.</p>

      <p>When you contact our support team, we collect the information provided in this communication.</p>

      <p>The Application also allows you to set personal preferences when searching for suitable connections, such as age and distance.</p>

      <h2>Sensitive personal data</h2>

      <p>Although we do not intentionally collect or process any sensitive personal data, registration on the {app} application or use of the {app} application could be considered in certain jurisdictions a processing of sensitive personal data (special categories of personal data). As optional data in the {app} application, you may also provide information about whether you are in a relationship or about your sexual identity or other sensitive personal data (special categories of personal data). You agree that you voluntary publish such personal data and grant us consent to their processing so that they may appear in your profile in the Application. You can delete such data from your profile anytime. Our processing of such data results from you providing such data to us and such data will not be used for any other purpose than operation of the Application.</p>

      <p>You can delete personal data by adapting your settings or contacting the administrator.</p>

      <h2>Information from other social sites</h2>

      <p>The {app} application allows you to link your account in the {app} application to accounts from other social sites, specifically Facebook, Instagram and Spotify. When linking to other social sites, you have the option of selecting which categories of data or what specific data (etc. photos) from other social sites you provide to the Application.</p>

      <p>When linking to an account on Facebook, the {app} application may acquire, in particular, the information specified above in the section “Information you provide” during registration.</p>

      <p>When linking to an account on Instagram, {app} acquires your Instagram ID and access to your photographs on Instagram.</p>

      <p>You acknowledge that linking to your account on Facebook or Instagram is voluntary and made on the basis of your request when registering with the Application. Such linking is not required for proper operation of the Application in case you provide the necessary information yourself without such linking.</p>

      <h2>Localization data</h2>

      <p>Localization data, i.e. information about your current location, are important for the proper functioning of the {app} application, especially when searching for suitable connections in your vicinity.</p>

      <p>{app} application requests that you provide access to the localization data on the mobile device where the {app} application is installed.</p>

      <p>If you allow access to your localization data, we are then able to collect and process information on your location. These data can be collected only if you are using the Application.</p>

      <p>We delete localization data immediately after they are no longer needed for functioning of the Application.</p>

      <h2>Message content</h2>

      <p>The {app} application allows you to chat with other users on the {app} application or to send information to us as the operator of the application.</p>

      <p>In all of these cases, we may collect and process the content of these messages (chats history).</p>

      <h2>Information we collect when you use the {app} application</h2>

      <p>We collect and process information about how you use the {app} application and what type of devices you use.</p>

      <p>This information includes the language setting of your mobile device or information about how you have used our services, time when you joined, time you spend using the Application, the features you use, interaction with other users ( whether you have marked a profile of another user as “Like” or “Dislike”, so that we can connect you when there is mutual agreement), the number of messages, the number of swipes and the number of connections.</p>

      <p>We also collect other information about the device you use including information about software and hardware (IP address, device type and ID).</p>

      <h2>Voluntary nature of personal data</h2>

      <p>Providing any personal data is completely voluntary. However, if you want to use the {app} application, you must at least provide the minimum scope of information specified in the section “Information you provide” during registration in the {app} application. The minimum required data are marked as “Required”. Providing other information is completely voluntary for the user.</p>

      <p>“Information we collect when you use the {app} application” is always collected only when you use the {app} application.</p>

      <h2>How we use the information we collect</h2>

      <p>We use the information we collect for operating the {app} application (i.e., for performing the contract that you have with us) and in this connection for improving and developing new services, and for protecting our company, you and other users of the {app} application.</p>

      <p>In addition, we use this information for marketing and commercial purposes so that we can offer you relevant products and services from our company. Thus, we can send you a commercial communication (such as an SMS, MMS, e-mail, etc.) using the electronic contact you provided to us. You are entitled at any time, even when sending individual messages, to decline any other commercial communication.</p>

      <p>We can use the data we collect for the following purposes, in particular for:</p>

      <p>Operating the {app} application and providing related services (recommending you the other users and right connections),
        Managing your profile, providing customer support, and communicating with you,
        Making an analysis relating to how you use the {app} application and what products and services you are interested in, including collecting and assessing data in analytic tools, such as Google Analytics,
        Creating, displaying, and monitoring advertisements adapted to your interests,
        Improving our services and developing new (new features based on interest our users),
        Preventing of criminal offences, breaches of conditions, fraud, verification of control notifications,
        Ensuring compliance with the law, assisting with law enforcement.
        The {app} application displays your profile created in the {app} application, especially your name, photographs, age, and distance, and any other information acquired from your profiles on other social sites that you have given the {app} application access to. When displaying your profile in the {app} application, we respect the privacy settings for your profile and restrictions on displaying certain information. You may restrict the visibility of your profile for other {app} application users.</p>

      <p>Our automatized systems analyze your photographs and recognize if there is a face, which is required, for example, in a profile photo. Our systems also analyze the location and profile quality of users based on which we can offer you the right connections with other users near you.

        We are able to connect all of the personal data we collect on the {app} application with information (including personal data) from other services associated with the {app} application.</p>

      <h2>Legal bases for process your information</h2>

      <h3>We process your information using the following legal basis:</h3>

      <p>Performing the contract when we have with you and providing our Service;
      Legitimate interests – for improving our Service, for keeping our users safe and for enforcing our rights;
      Complying with applicable laws and regulations; and
      If you provide us with your consent, we may also process data based on your consent.</p>
      
      <h3>To whom do we disclose the information we collect?</h3>

      <p>Disclosure to other users of the {app} application</p>

      <p>Your profile in the {app} application is, due to the purpose of this Application, visible to other {app} application users.</p>

      <p>When displaying your profile in the {app} application, we respect the privacy settings of your profile and the restrictions on certain information. Therefore, consider providing the data with respect to such publication and adjust your privacy settings based on your preferences.</p>

      <p>If you “Like” or “Power Message” another user and this user “Likes” or “Power Message” you, thus making it a “Match”, this other user is informed about this, and you can initiate communication with this user using the chat function in the {app} application.</p>

      <p>When authorizing the connection of your profile to other social sites, such as Facebook, Instagram or Spotify, other information is also visible, such as photographs and interests, and also information that you have in common with another user that browses your profile, such as common friends or interests.</p>

      <p>If someone submits a report involving you, such as inappropriate behaviour or violation of our Terms & Conditions, we may communicate to the reporting person the actions, if any, we took because of the report.</p>

      <h2>Third-party disclosure – personal data processors</h2>

      <p>We provide information that we process, including personal data, only in the necessary scope to third parties that provide us with services, in particular, cloud data centers, services for automatic evaluation of the content of photographs, communication, data hosting and maintenance providers, services for payment process, security operations, analytics suppliers, application developers and customer care center service. When choosing third parties, we pay attention to the reputation of these subjects and require their strict adherence to personal data protection requirements, corresponding to the high standards of regulations in the European Union.</p>

      <p>We never provide your data to third parties for any marketing or commercial purpose.</p>

      <h2>Other disclosure</h2>

      <p>We could be required to provide your personal data under law or for fulfilling mandatory duties (e.g. for criminal, court, or administrative proceedings). In this case we are authorized, if necessary, to disclose your personal data in order to protect our rights or the rights of third parties or in order to protect users of the {app} application.</p>

      <p>We are authorized, if necessary, to disclose your personal data in order to enforce our agreements with you and to mitigate the liability of the {author}.</p>

      <h2>Child Sexual Abuse Regulation and User Confidentiality</h2>

      <p>We prioritize your safety and security. We operate in accordance with the Child Sexual Abuse Regulation, withing the legal bounds allowing derogations from ePrivacy rules to combat child sexual abuse effectively. We are dedicated to child safety and we are cooperating with local law enforcement in cases of child sexual abuse regulation breaches. In exceptional cases, when child sexual abuse is identified and/or reported by law enforcement, authorized personnel processes user data and/or securely shares them to the law enforcement.</p>

      <h2>Your rights (Access to and updating personal data)</h2>

      <p>We provide you access to your personal data each time you use the {app} application.</p>

      <p>You have control over your personal data. You may change, delete or update any of the personal data at any time in the Settings section.</p>

      <p>Whilst respecting limits and rules of the applicable regulation, you have the following rights:</p>

      <p>the right of access to personal data, i.e. the right to obtain confirmation from us as to whether or not personal data concerning you are being processed and, if so, the right to obtain access to such personal data and information on:</p>
      <li>purposes of processing;</li>
      <li>the categories of personal data concerned;</li>
      <li>the recipients or categories of recipients to whom the personal data have been or will be disclosed;</li>
      <li>the planned period of storage of personal data or the criteria used to determine this period;</li>
      <li>the existence of the right to request us to correct or delete personal data or to limit their processing and / or to object to the processing of personal data;</li>
      <li>the right to lodge a complaint with the supervisory authority;</li>
      <li>sources of personal data, if these are not obtained from the applicant;</li>
      <li>the implementation of automated decision-making, including profiling, and information on the procedure used as well as the relevance and expected consequences for the applicant;</li>
      <li>on the transfer of personal data to countries outside the European Union (so-called third countries) or international organizations and on appropriate guarantees for the processing of personal data provided in connection with their transfer;</li>
      <li>the right to receive a copy of the processed personal data;</li>
      <li>the right to correct personal data if his personal data are inaccurate;</li>
      <li>the right to have personal data erased ("right to be forgotten") if: personal data are no longer needed for the purposes for which they were collected or otherwise processed, the consent to the processing of personal data is revoked and there is no other legal reason for the processing, legitimate objections are raised against the processing of personal data, personal data have been processed illegally, personal data must be deleted in order to fulfil a legal obligation binding on the {author};</li>
      <p>the right to restrict the processing if:</p>
      <li>the accuracy of personal data is denied for the time necessary for us to verify the accuracy of personal data;</li>
      <li>the processing of personal data is illegal, but instead of deleting personal data, restrictions on their use are required;</li>
      <li>We no longer need personal data for processing purposes, but the applicant requires them to determine, enforce or defend legal claims;</li>
      <li>an objection has been raised against the processing in the case of the processing of personal data for the purposes of the legitimate interests of us until it is verified whether the legitimate reasons of us outweigh the legitimate reasons of the objecting person;</li>
      <li>the right to data portability, i.e. the right to obtain personal data concerning him in a structured, commonly used and machine-readable format, and the right to transfer such data to another controller (or to request the transfer of data directly by us to another controller), in case of that the processing of personal data is based on consent or a contract and is carried out automatically;</li>
      <li>the right to object, ie the right to object to the processing of personal data for the purposes of our legitimate interests;</li>
      <p>the right to file a complaint with the Office for Personal Data Protection or at the competent authority.</p>
      <p>In this respect, you may change the settings on your device as access to contacts, photo gallery, location, notifications etc. Please note that if you disable certain accesses, our Service may not be complete and lose functionality or part thereof.</p>

      <p>You may also request information or change the settings by sending a request to the e-mail address: {email} or cancel your profile in the {app} application at any time in Settings – Private – Delete Account.</p>

      <p>Please note that uninstalling the application from your device, will not delete your Account.</p>

      <p>For the purpose of your safety and the safety of other users, we may ask you to provide us with proof of your identity. We may also reject your requests in the case that the request is unlawful, if it may infringe trade secrets or the privacy of other users.</p>

     <p> If you request information relating to other users, such as message history, the concerned user must give us his explicit written consent before providing this information.</p>

      <h2>Storage period of your information</h2>

      <p>Your personal data are stored only as long as we need it for legitimate reasons and as it is required by applicable law. We store your personal data for the duration of your profile in the {app} application, unless stated otherwise.</p>

      <p>After you delete your profile in the {app} application, some of the data may still be stored by us, for legitimate or technical reasons, in our databases or the databases of our partner(s) for a limited period of time.</p>

      <h2>We will delete your personal data as follows:</h2>

      <p>If you require deletion or if you delete your Account all personal data, including your profile, are deleted within 6 months of receiving the request – this period is set to protect our users and us and may further be prolonged in justified cases (pending legal action, investigation by authorities etc.). This period is necessary e.g. to investigate unappropriated behaviors;
      After 90 days of your inactivity your swipes and matches will be deleted.
      Protection and security of personal data</p>

      <p>We make every effort to protect you from unauthorized access or unauthorized changes, publication, or deletion of the information we collect.</p>

      <p>We disclose personal data only to employees and contractual partners who need to process the data on our behalf. All employees and contractual partners are bound by the duty to uphold confidentiality.</p>

      <p>We protect personal data to the maximum extent possible with the help of advanced encryption technologies.</p>

      <p>We regularly inspect and update the process of collection, storage, and processing of information in order to prevent unauthorized access to our systems.</p>

      <p>However, we are not liable for any unauthorized infringement by third parties, as a result of which these parties obtain unauthorized access to your profiles or personal data and misuse these data or disclose them to third parties, if such infringement took place as a result of circumstances which we are not able to influence.</p>

      <p>If, despite all our efforts, an unauthorized leak of personal data will take place, we might need to notify the relevant supervisory authority and follow procedure prescribed by law in this respect.</p>

      <h2>Where are your data collected?</h2>

      <p>Your personal data are collected and processed mainly at our Provider Google Cloud EMEA.</p>

      <p>In view of the fact that we use cloud services for the {app} application and your personal data may be stored in data centres of these providers, your personal data may also be transferred to other EU countries and to the USA or other jurisdictions that have different privacy laws. All processors of personal data with a processing location in the USA are bound under the SCC („Standard contractual clauses for data transfers between EU and non-EU countries“) or another transfer mechanism that the competent authorities consider adequate. All our providers ensure the protection of personal data in compliance with the General Data Protection Regulation (GDPR) applicable in the European Economic Area.</p>

      <h2>Changes to the privacy policy</h2>

      <p>Our privacy policy may be amended from time to time.</p>

      <p>The current version of the privacy policy, all changes, and all previous versions will be available on these web pages. If it involves significant changes, we will prepare for you a summary and explanation.</p>

      <p>You acknowledge these changes to the privacy policy by continuing to use the {app} application after being notified of the changes and their effective date. If you do not agree to the changes, discontinue your use of the {app} application and delete your profile from the {app} application.</p>

      <h2>How to contact us</h2>

      <p>Should you have any questions or comments relating to the privacy policy, please contact us at the following e-mail address: {email} or to the address {author}, {address}.</p>



      <p>This Privacy Policy was last updated 30 of March 2024</p>




    </div>
  )
}
