import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import './App.css';
import Home from "./pages/home/Home";
import Mb from "./pages/mb/Mb";
import Privacy from "./pages/common/privacy/Privacy";
import Terms from "./pages/common/terms/Terms";
import Nb from "./pages/nb/Nb";
import Support from "./pages/common/support/Support";
import Iterm from "./pages/iterm/Iterm";

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mb" element={<Mb />} />
      <Route path="/nb" element={<Nb />} />
      <Route path="/iterm" element={<Iterm />} />

      <Route path="/mb/privacy" element={<Privacy appLong="MyBOY"/>} />
      <Route path="/nb/privacy" element={<Privacy appLong="nextBoy"/>} />
      <Route path="/iterm/privacy" element={<Privacy appLong="iTerminalSSH"/>} />

      <Route path="/mb/terms" element={<Terms  appLong="myBOY"/>} />
      <Route path="/nb/terms" element={<Terms  appLong="nextBoy"/>} />
      <Route path="/iterm/terms" element={<Terms  appLong="iTerminalSSH"/>} />

      <Route path="/mb/support" element={<Support  appLong="myBOY"/>} />
      <Route path="/nb/support" element={<Support  appLong="nextBoy"/>} />
      <Route path="/iterm/support" element={<Support  appLong="iTerminalSSH"/>} />

      </Routes>
      </Router>
      
    </div>
  );
}
export default App;