import React from 'react'
import { Link } from 'react-router-dom'
import "./home.scss"
export default function Home() {
  return (
    <div className="home">
      <div className="row">
        <div className="col">
          <Link to="/mb"><img src='./app.icon.mb.png' alt="myBoy"></img></Link>
          <div className="name">myBOY (iOS)</div>
        </div>


        <div className="col">
          <Link to="/nb"><img src='./app.icon.nb.png' alt="nextboy"></img></Link>
          <div className="name">nextBoy (iOS)</div>
        </div>

        <div className="col">
          <Link to="/iterm"><img src='./app.icon.iterm.png' alt="iTerminalSSH"></img></Link>
          <div className="name">iTerminalSSH (macOS)</div>
        </div>



      </div>
    </div>
  )
}
